import React from 'react';
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer>
      <div className="footer-bg">
        <div className="row mx-0">
          <div className="col-12 col-sm-12 col-md-8 container-lg-7 container-xl-6 mt-tf">
            <div className="text-footer mx-4 px-2">¿Quieres más consejos y recetas de preparación fáciles? ¡Encuéntranos en las redes sociales!</div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 container-lg-5 container-xl-6">
            <div className="row icons-social">
              <div className='col mx-0 px-0'><Link to="https://www.facebook.com/profile.php?id=61560448727499" target="_blank" className="elementor-icon elementor-social-icon"><i className="fab fa-facebook-f"></i></Link></div>
              <div className='col mx-0 px-0'><Link to="https://www.instagram.com/reynolds_latam" target="_blank" className="elementor-icon elementor-social-icon"><i className="fab fa-instagram"></i></Link></div>
              <div className='col mx-0 px-0'><Link to="https://www.youtube.com/channel/UC1ONwe2LZzTU28Pm7KPxcsQ" target="_blank" className="elementor-icon elementor-social-icon"><i className="fab fa-youtube"></i></Link></div>
              <div className='col mx-0 px-0'><Link to="http://www.pinterest.com/reynoldsmex" target="_blank" className="elementor-icon elementor-social-icon"><i className="fab fa-pinterest"></i></Link></div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="row mx-0">
          <div className="col-12 col-sm-12 col-md-6 terms"><Link to="https://www.reynoldsconsumerproducts.com/privacy" target="_blank">Términos y condiciones</Link></div>
          <div className="col-12 col-sm-12 col-md-6 copyright">© 2024 Reynolds Consumer Products</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
