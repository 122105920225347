const landingImages = require.context('../../../assets/images', true);
function Product({title, image, text}) {
  return (
    <product>
      <div className="row mb-4">
        <div className="col-12 text-center"><img src={landingImages(`./${image}.png`)} alt="Reynolds" className="product_img"/></div>        
        <div className="col-12 landing_h5 text-center mb-3">{title}</div>
        <div className="col-12 product_text text-center product_min_height" dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
    </product>
  );
}

export default Product;
