//import Footer from './components/Footer';
//import Header from './components/Header';
import './App.css';
/*import TopSection from './components/TopSection';
import MiddleSection from './components/MiddleSection';
import WhereBuy from './components/WhereBuy';
import ProductsSection from './components/ProductsSection';*/
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PuertoRico from "./pages/pr/components/Home";
import Home from "./components/Home";
import NotFound from "./NotFound";

//const landingImages = require.context('./assets/images', true);
function App() {
  return (
    <div className="App">
      <Router>        
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/pr" element={<PuertoRico/>} />  
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </Router>      
    </div>
  );
}

export default App;
