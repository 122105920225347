import Product from './Product';
function ProductsSection() {
  return (
    <div className='container mt-5'>
          <div className='row  justify-content-center text-center pt-3'>
            <div className='col-12 col-md-8 col-lg-9 landing_h3 text-center' id="productos">Productos</div>
          </div>
          <div className='container me-lg-0 my-5 ms-lg-auto'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                <Product 
                  title='Bandeja rectangular'
                  image='reynolds-bandeja-rectangular'
                  text={`Nuestra bandeja rectangular de 30,5x 23x 6,4 
                    cm es perfecta para tu hornito eléctrico, 
                    recuerda que viene en pack individual lista 
                    para preparar tus mejores platillos.`}
                />
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                <Product 
                  title='Bandeja cuadrada'
                  image='reynolds-bandeja-cuadrada'
                  text={`Nuestra bandeja cuadrada de 20x 20x 4,6cm 
                    es perfecta para tus preparaciones en hornito 
                    eléctrico, viene en su pack individual, ideal 
                    para calentar y consumir al momento.`}
                />
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 col-12'>
                <Product 
                  title='Bandeja circular'
                  image='reynolds-bandeja-circular'
                  text={`Nuestra bandeja circular de 22,2 cm x 2,8cm 
                   espera por hornear tus mejores postres en tu 
                   hornito eléctrico, recuerda que viene en pack 
                   individual lista para tus mejores creaciones.`}
                />
              </div>
            </div>
          </div>
        </div>
  );
}

export default ProductsSection;
