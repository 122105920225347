const landingImages = require.context('../assets/images', true);
function MiddleSection() {
  return (
    <>
      <div className='container mt-5'>
        <div className='container me-lg-0 my-5 ms-lg-auto'>
            <div className='row justify-content-lg-end align-items-center'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                  <img alt='Kitchen' src={landingImages('./image-kitchen.png')} className="img-fluid" width="100%" height="100%" />
                </div>
                <div className='col-lg-6 col-xl-6'>
                  <div className='row  justify-content-center pt-3'>
                    <div className='col-12 mb-4 landing_h3'>La cocina es donde todo se une</div>
                    <div className='col-12 mb-4 text_content'>Sabores, ideas, familia, amigos. Es donde lo dulce y lo salado se combinan con historias y experiencias. Por eso, en Reynolds celebramos las diversas formas en que las comunidades hacen que nuestros productos sean parte de su vida cotidiana.</div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}

export default MiddleSection;