const landingImages = require.context('../../../assets/images', true);
function TopSection() {
  return (
    <>
      <div className='px-0 header-background' style={{backgroundImage: landingImages('./background-top.png')}}>
          <div className='container my-5'>
            <div className='row justify-content-center align-items-center mx-0 px-0'>
                <div className='col-lg-6 col-xxl-6 col-xl-6 col-12'>
                  <div className='row justify-content-center pt-3 texttop'>
                    <div className='col-12 col-md-10 mb-2'><span className='landing_h2'>Calidad</span> <div className="container_parts_h2"><span className='landing_part2_h2'>a tu</span> <span className='landing_part3_h2'>medida</span></div></div>
                    <div className='col-12 col-md-10 mt-1 mb-3'><span className='subtitle_h2 mb-5'>ahora en pack individual</span></div>
                    <div className='col-12 col-md-10 mb-4 product_text_top text-white'>La facilidad y practicidad de tu cocina ahora es más fácil gracias a las bandejas individuales para hornitos eléctricos Reynolds ¡Ve por las tuyas ahora!</div>
                    <div className='col-12 col-md-10 mb-4'>
                      <div className='discover'>
                        <a href="#productos">DESCUBRIR MÁS <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M21.0459 13.2959L14.2959 20.0459C14.0846 20.2573 13.7979 20.376 13.4991 20.376C13.2002 20.376 12.9135 20.2573 12.7022 20.0459C12.4908 19.8346 12.3721 19.5479 12.3721 19.2491C12.3721 18.9502 12.4908 18.6635 12.7022 18.4522L17.5312 13.625H3.75C3.45163 13.625 3.16548 13.5065 2.9545 13.2955C2.74353 13.0845 2.625 12.7984 2.625 12.5C2.625 12.2016 2.74353 11.9155 2.9545 11.7045C3.16548 11.4935 3.45163 11.375 3.75 11.375H17.5312L12.7041 6.545C12.4927 6.33365 12.374 6.04701 12.374 5.74812C12.374 5.44924 12.4927 5.16259 12.7041 4.95125C12.9154 4.7399 13.2021 4.62117 13.5009 4.62117C13.7998 4.62117 14.0865 4.7399 14.2978 4.95125L21.0478 11.7012C21.1527 11.8059 21.2359 11.9303 21.2926 12.0672C21.3493 12.2041 21.3784 12.3508 21.3782 12.499C21.3781 12.6472 21.3486 12.7938 21.2916 12.9306C21.2346 13.0674 21.1511 13.1915 21.0459 13.2959Z" fill="#001489"/>
                      </svg></a></div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-xxl-5 col-lg-6 col-md-6 col-12 px-0 d-none d-lg-grid'>
                  <div className="container me-lg-0 px-0">
                    <img alt='Kitchen' src={landingImages('./image-reynolds-top-new.png')} className="img-fluid" width="95%" height="95%" />
                  </div>
                </div>
            </div>
          </div>
      </div>
      <div className='bg-blue mb-5' id="quienessomos">
        <div className='container'>
          <div className='container me-lg-0 my-5 ms-lg-auto'>
              <div className='row justify-content-lg-end align-items-center'>
                  <div className='col-lg-5 col-xl-5 col-12'>
                    <div className='row  justify-content-center pt-3'>
                      <div className='col-12 mb-4 landing_h3'>Durante más de 75 años</div>
                      <div className='col-12 mb-4 text_content'>Hemos estado en la cocina facilitando las comidas, para que puedas hacerlas especiales. Desde la preparación hasta la cocción y la limpieza, somos el asistente ingenioso a tu lado, siempre allí cuando nos necesitas.</div>
                    </div>
                  </div>
                  <div className='col-xl-7 col-lg-7 col-md-7 col-12 px-0 order-first order-lg-last'>
                    <img alt='Kitchen' src={landingImages('./75-years.png')} className="img-fluid" width="100%" height="100%" />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopSection;
