import Modal from '@mui/material/Modal';
import Store from './Store';
function ModalComponent({showModal, handleCloseModal, handleGoStore}) {
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
      <div className='modal-base py-5 px-3'>
        <div className='row justify-content-center align-items-center'>
            <div className='col-12'>
                <div className='landing_h3 text-center mb-4'>Dónde comprar</div>
                <div className='stores mb-4 px-2'>                  
                  <Store logo='MrSpecial.png' title='Mr. Special' handle={(e) => handleGoStore('https://supermercadovirtual.shop/#','Mr. Special', e)}/> 
                  <Store logo='Capri.png' title='Capri' handle={(e) => handleGoStore('https://tiendascapri.com/','Capri', e)}/>
                  <Store logo='Selectos.jpg' title='Selectos' handle={(e) => handleGoStore('https://www.selectoseasyshop.com/shop#!/?q=reynolds','Selectos', e)}/>
                  <Store logo='Econo.png' title='Econo' handle={(e) => handleGoStore('https://www.superecono.com/shopper/#shoppertop','Econo', e)}/>
                  <Store logo='walmart.png' title='Walmart' handle={(e) => handleGoStore('https://walmartpr.com/publicaciones','Walmart', e)}/>                
                </div>
                <button className='button-modal' onClick={handleCloseModal}>X</button>
            </div>
        </div>
      </div>
  </Modal>
  );
}

export default ModalComponent;
