import Modal from '@mui/material/Modal';
import Store from './Store';
function ModalComponent({showModal, handleCloseModal, handleGoStore}) {
  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
      <div className='modal-base py-5 px-3'>
        <div className='row justify-content-center align-items-center'>
            <div className='col-12'>
                <div className='landing_h3 text-center mb-4'>Dónde comprar</div>
                <div className='stores mb-4 px-2'>
                  <Store logo='walmart.png' title='Walmart' handle={(e) => handleGoStore('https://super.walmart.com.mx/search?q=reynolds+','Walmart', e)}/>
                  <Store logo='chedraui.png' title='Chedraui' handle={(e) => handleGoStore('https://www.chedraui.com.mx/reynolds?_q=reynolds&map=ft','Chedraui', e)}/>
                  <Store logo='soriana.png' title='Soriana' handle={(e) => handleGoStore('https://www.soriana.com/buscar?q=reynolds&search-button=','Soriana', e)}/>
                  <Store logo='heb.png' title='Heb' handle={(e) => handleGoStore('https://www.heb.com/search/?q=reynolds','Heb', e)} />
                  <Store logo='lacomer.png' title='La comer' handle={(e) => handleGoStore('https://www.lacomer.com.mx/lacomer/#!/item-search/287/reynolds/false?p=1&t=0&succId=287&succFmt=100','La comer', e)}/>
                </div>
                <button className='button-modal' onClick={handleCloseModal}>X</button>
            </div>
        </div>
      </div>
  </Modal>
  );
}

export default ModalComponent;
