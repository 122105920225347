import React from "react";
import Footer from './Footer';
import Header from './Header';
import '../App.css';
import TopSection from './TopSection';
import MiddleSection from './MiddleSection';
import WhereBuy from './WhereBuy';
import ProductsSection from './ProductsSection';


function Home() {
  return (
    <div>
      <Header />
      <TopSection />
      <MiddleSection />
      <ProductsSection />
      <div className='footer-top-bg'>
      <WhereBuy/>
      <Footer />
      </div>
    </div>
  );
}

export default Home;